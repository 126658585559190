import React, {useRef, useState} from 'react'
import emailjs from "@emailjs/browser";
import {FaRegUser} from "react-icons/fa";
import { MdOutlineEmail, MdOutlineTextSnippet } from "react-icons/md";
import { HiMiniPhone } from "react-icons/hi2";
import { GrCatalogOption } from "react-icons/gr";
import { GrDomain } from "react-icons/gr";
import ReCAPTCHA from "react-google-recaptcha";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactWeb = () => {
    const formRef = useRef(null);
    const recaptchaRef = useRef(null);

    const recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        nip: '',
        companyName: '',
        phone: '',
        domain: '',
        packageOption: '',
        options: {
            wersjaJezykowa: false,
            liveChat: false,
            googleAds: false,
            logo: false,
            coprywriting: false,
            fb: false,
            maps: false,
            domena: false,
        }
    });
    const handleSelectChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            packageOption: e.target.value,
        }));
    }

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };
    const handleCheckboxChange = (e) => {
        const { id, checked } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            options: {
                ...prevData.options,
                [id]: checked,
            },
        }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = await recaptchaRef.current.executeAsync();

        if(!token) {
            toast.error("Weryfikacja reCAPTCHA nie powiodła się.");
            return;
        }


        const fieldsFilled = formData.email && formData.fullName && formData.packageOption;
        if (!fieldsFilled) {
            toast.warn("Upewnij się, że wszystkie pola zostały poprawnie wypełnione.");
            return;
        }

        const selectedOptions = Object.entries(formData.options)
            .filter(([key, value]) => value)
            .map(([key]) => key)
            .join(', ');

        try {

            const templateParams = {
                fullName: formData.fullName,
                email: formData.email,
                nip: formData.nip || "Nie podano",
                companyName: formData.companyName || "Nie podano",
                phone: formData.phone || "Nie podano",
                domain: formData.domain,
                packageOption: formData.packageOption,
                selectedOptions: selectedOptions || "Brak opcji dodatkowych",
            };

            await emailjs.send(
                process.env.REACT_APP_SERVICE_ID,
                process.env.REACT_APP_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_OPTIONS,
                token,
            );

            if (window.gtag) {
                window.gtag('event', 'conversion', {
                    send_to: 'AW-11533927081/BSmSCPTCqP8ZEKmF5_sq',
                    value: 1.0,
                    currency: 'PLN',
                });
            }

            toast.success("Formularz został wysłany pomyślnie!")

            setFormData({
                fullName: '',
                email: '',
                nip: '',
                companyName: '',
                phone: '',
                domain: '',
                packageOption: '',
                options: {
                    wersjaJezykowa: false,
                    liveChat: false,
                    googleAds: false,
                    logo: false,
                    coprywriting: false,
                    fb: false,
                    maps: false,
                    domena: false,
                }
            });

        } catch (e) {
            toast.error('Wystąpił błąd podczas wysyłania wiadomości.');
            console.error('Failed...', e);
        }
    };

    return (
        <div className="w-full mb-[200px] sm:mt-[0px] md:mt-[0px] mt-[0px]" id="contact">
            <div
                className="max-w-[1240px] mx-auto rounded-xl bg-[white] pt-[20px] pb-[20px] pl-[20px] pr-[20px] md:pl-[40px]"
            >
                <h1 className="text-[28px] md:text-[48px] font-bold">Zamów Stronę</h1>
                <p className="text-[16px] mulish font-normal text-[#2D2D2D]">
                    Z przyjemnością odpowiemy na Twoje zapytania i doradzimy najlepsze rozwiązania dla Twojego biznesu.
                </p>

                <form ref={formRef} onSubmit={handleSubmit} className="grid pt-[25px]">
                    <div className="grid md:grid-cols-2 gap-4">
                        <div>
                            <div className="relative py-2">
                                <FaRegUser size={16}
                                           className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2"
                                           color="#932F6D"/>
                                <input
                                    type="text"
                                    id="fullName"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Imię i Nazwisko"
                                    required
                                />
                            </div>
                            <div className="relative py-2">
                                <MdOutlineEmail size={18}
                                           className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2"
                                           color="#932F6D"/>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Adres E-mail"
                                    required
                                />
                            </div>
                            <div className="relative py-2">
                                <GrCatalogOption size={16}
                                           className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2"
                                           color="#932F6D"/>
                                <input
                                    type="text"
                                    id="companyName"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Nazwa Firmy (Opcjonalnie)"
                                />
                            </div>
                        </div>
                        <div>
                            <div className="relative py-2">
                                <MdOutlineTextSnippet size={16}
                                           className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2"
                                           color="#932F6D"/>
                                <input
                                    type="text"
                                    id="nip"
                                    name="nip"
                                    value={formData.nip}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="NIP (Opcjonalnie)"
                                />
                            </div>
                            <div className="relative py-2">
                                <HiMiniPhone size={18}
                                           className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2"
                                           color="#932F6D"/>
                                <input
                                    type="tel"
                                    id="phone"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Telefon kontaktowy (Opcjonalnie)"
                                />
                            </div>
                            <div className="relative py-2">
                                <GrDomain size={16}
                                           className="fas fa-user absolute left-3 top-1/2 transform -translate-y-1/2"
                                           color="#932F6D"/>
                                <input
                                    type="text"
                                    id="domain"
                                    name="domain"
                                    value={formData.domain}
                                    onChange={handleChange}
                                    className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-[#932F6D]"
                                    placeholder="Proponowana domena (np. nazwa.pl) (Opcjonalnie)"

                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-4">
                        <div className="w-1/3">

                            <label htmlFor="pakiet"
                                   className="block mb-2 font-bold text-black">Wybierz pakiet:</label>
                            <select id="pakiet" onChange={handleSelectChange} value={formData.packageOption}
                                    className="rounded-lg p-2 border border-[black] focus:ring-[#932F6D] w-[100%]"
                            required>
                                <option selected>Wybierz pakiet</option>
                                <option value="start">START</option>
                                <option value="simple">SIMPLE</option>
                                <option value="full">FULL</option>
                            </select>
                        </div>
                        <div className="w-2/3">
                            <p className="block mb-2 font-bold text-black">Opcje dodatkowe:</p>
                            <div className="grid md:grid-cols-3">
                                <div>

                                    <div className="flex items-center mb-4">
                                        <input id="wersjaJezykowa" type="checkbox"
                                               checked={formData.options.wersjaJezykowa}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="wersja-jezykowa"
                                               className="ms-2 text-sm font-medium text-[black]">Dodatkowa wersja
                                            językowa</label>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <input id="liveChat" type="checkbox" value=""
                                               checked={formData.options.liveChat}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="live-chat"
                                               className="ms-2 text-sm font-medium text-[black]">Moduł Live Chat</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input id="googleAds" type="checkbox" value=""
                                               checked={formData.options.googleAds}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="google-ads"
                                               className="ms-2 text-sm font-medium text-[black]">Reklama Google
                                            Ads</label>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center mb-4">
                                        <input id="logo" type="checkbox" value=""
                                               checked={formData.options.logo}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="logo"
                                               className="ms-2 text-sm font-medium text-[black]">Projekt logo</label>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <input id="coprywriting" type="checkbox" value=""
                                               checked={formData.options.coprywriting}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="copywriting"
                                               className="ms-2 text-sm font-medium text-[black]">Napisanie tekstów na
                                            stronę</label>
                                    </div>
                                    <div className="flex items-center">
                                        <input id="fb" type="checkbox" value=""
                                               checked={formData.options.fb}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="fb"
                                               className="ms-2 text-sm font-medium text-[black]">Integracja z
                                            Facebook</label>
                                    </div>
                                </div>
                                <div>
                                    <div className="flex items-center mb-4">
                                        <input id="maps" type="checkbox" value=""
                                               checked={formData.options.maps}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="maps"
                                               className="ms-2 text-sm font-medium text-[black]">Rejestracja w mapach
                                            Google</label>
                                    </div>
                                    <div className="flex items-center mb-4">
                                        <input id="domena" type="checkbox" value=""
                                               checked={formData.options.domena}
                                               onChange={handleCheckboxChange}
                                               className="w-4 h-4 text-[#932F6D] bg-gray-100 border-gray-300 rounded focus:ring-[#932F6D] dark:focus:ring-[#932F6D]"/>
                                        <label htmlFor="domena"
                                               className="ms-2 text-sm font-medium text-[black]">Rejestracja
                                            domeny</label>
                                    </div>
                                </div>
                            </div>
                            <button
                                type="submit"
                                className="w-full mt-6 bg-[#932F6D] text-white font-bold py-3 px-6 rounded-lg hover:bg-black transition-colors"
                            >
                                Wyślij
                            </button>

                        </div>
                    </div>
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={recaptchaSiteKey}
                        size="invisible"
                        className=""
                    />
                </form>
                <ToastContainer position="top-right" autoClose={5000} />
            </div>
        </div>
    );
}

export default ContactWeb;