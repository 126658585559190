import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './index.css';

//FONTS

// Poppins
import "@fontsource/poppins";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/400-italic.css";
// Mulish
import "@fontsource/mulish";
import "@fontsource/mulish/400.css";
import "@fontsource/mulish/400-italic.css";

import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import OfferWeb from "./pages/OfferWeb";
import ScrollToTop from "./hooks/ScrollToTop";
import SocialOffer from "./pages/SocialOffer";
import ECommerceOffer from "./pages/ECommerceOffer";
import Blog from "./pages/Blog";
import ArticlePage from "./pages/ArticlePage";
import {Helmet} from "react-helmet";



function App() {
  return (
    <div>
        <Helmet>
            <title>Agencja Marketingowa HKCode | Projektowanie Stron, Media Marketing, AI Marketing</title>
            <meta name="description" content="Agencja marketingowa HKCode oferuje kompleksowe usługi w zakresie projektowania stron internetowych, media marketingu oraz wykorzystywania sztucznej inteligencji w marketingu. Skuteczne strategie online dla Twojej firmy!"/>
            <meta name="keywords" content="agencja marketingowa, projektowanie stron, media marketing, AI marketing, strategie online"/>
            <meta name="author" content="Agencja Marketingowa HKCode"/>
            <meta property="og:title" content="Agencja Marketingowa HKCode | Projektowanie Stron, Media Marketing, AI Marketing"/>
            <meta property="og:description" content="Agencja marketingowa HKCode oferuje kompleksowe usługi w zakresie projektowania stron internetowych, media marketingu oraz wykorzystywania sztucznej inteligencji w marketingu. Skuteczne strategie online dla Twojej firmy!"/>
            <meta property="og:type" content="website"/>
            <meta property="og:url" content="https://hkcode.pl"/>
            <meta property="og:image" content="https://hkcode.pl/"/>
            <meta name="twitter:title" content="Agencja Marketingowa HKCode | Projektowanie Stron, Media Marketing, AI Marketing"/>
            <meta name="twitter:description" content="Agencja marketingowa HKCode oferuje kompleksowe usługi w zakresie projektowania stron internetowych, media marketingu oraz wykorzystywania sztucznej inteligencji w marketingu. Skuteczne strategie online dla Twojej firmy!"/>

        </Helmet>
        <Router>
            <ScrollToTop/>
            <Routes>
                <Route index element={<Home/>}/>
                <Route path="/" element={<Home/>}/>
                <Route path="/home" element={<Home/>}/>
                <Route path="/offer_web" element={<OfferWeb/>}/>
                <Route path="/offer_social" element={<SocialOffer/>}/>
                <Route path="/offer_ecommerce" element={<ECommerceOffer/>}/>
                <Route path="/blog" element={<Blog/>}/>
                {/* Articles */}
                <Route path="/article/:id" element={<ArticlePage/>}/>
                {/* No page component */}
                <Route path="*" element={<NoPage/>}/>
            </Routes>
        </Router>
    </div>
  );
}

export default App;
