import logo from '../assets/logo_foot.png';
import { FiAlignLeft } from "react-icons/fi";
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { ImFacebook2 } from "react-icons/im";
import { FaInstagram } from "react-icons/fa";
import React from "react";
import {Link} from "react-router-dom";

const Footer = () => {

    const lista = ["O NAS", "OFERTA", "PORTFOLIO", "OPINIE"];

    return (
        <div className="w-full">
            <div className="max-w-[1240px] mx-auto border-t-[2px] border-[#932F6D] px-4">
                <div className="grid md:grid-cols-2 pt-[5rem] pb-[5rem]">
                    <div className="flex">
                        <img src={logo} alt="hkcode logo" className="md:ml-[100px] w-[101px] h-[110px]"/>
                    </div>
                    <div className="gap-10">
                        <div className="grid md:grid-cols-2 gap-1 md:pt-0 pt-[20px]">
                            <div className="flex gap-2">
                                <FiAlignLeft size={24} color="#932F6D"/> <span>NIP: 8871829013 REGON: 529822646</span>
                            </div>
                            <div className="flex gap-2">
                                <MdOutlineMarkEmailRead size={24} color="#932F6D"/> <span><Link to="mailto:bok@hkcode.pl">bok@hkcode.pl</Link></span>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-2 gap-1">
                            <div className="flex gap-2">
                                <FiPhoneCall size={22} color="#932F6D"/> <span><Link to="tel:+48724598509">+48 724 598 509</Link></span>
                            </div>
                            <div className="flex gap-2">
                            <FiPhoneCall size={22} color="#932F6D"/> <span><Link to="tel:+48536276886">+48 536 276 886</Link></span>
                            </div>
                        </div>
                        <div className="flex gap-10 mt-[40px]">
                            <span className="text-[#0A142F]">Social media:</span> <span className="flex gap-4"><Link to="https://www.facebook.com/profile.php?id=61560462115946" target="_blank" alt="Facebook Icon redirect to facebook profile"><ImFacebook2 size={22} color="#932F6D" className="hover:scale-105 cursor-pointer"/></Link><Link to="https://www.instagram.com/hkcodepl" target="_blank" alt="Instagram Icon redirect to instagram profile"><FaInstagram size={24} color="#932F6D" className="hover:scale-105 cursor-pointer"/></Link></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="max-w-[1240px] mx-auto border-t-[2px] border-[#932F6D] border-opacity-20 px-4">
                <div className="grid md:grid-cols-2 pt-[30px] pb-[5rem]">
                    <div>
                        <ul className="flex text-[#0A142F]">
                            {lista.map((element, index) => (
                                <Link to={`/home#${element.toLowerCase().replace(' ', '-')}`}>
                                    <li
                                        key={index}
                                        className={`flex flex-row hover:scale-105 pl-0 px-4 cursor-pointer text-[16px] h-[45px]`}

                                    >
                                        {element}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className="flex md:flex-row-reverse">
                        <span className="text-[#0A142F]">Copyright &copy; 2024 HKCODE</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;